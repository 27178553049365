<template>
    <v-app>
        <SingleAppBar title="Laboratory" :backward="true">
            <template v-slot:extension>
            <v-tabs
              v-model="tab"
              background-color="white"
              fixed-tabs
              icons-and-text
              height="50"
            >
              <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in items"  :key="item.title">
                {{ item.title }}
            </v-tab>
        
            </v-tabs>
          </template>
        </SingleAppBar>
        <v-main class="natural lighten-4 fill-height">
          <v-container fluid class="pt-15 mt-5">
          <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.title">
          <v-row>
            <v-col cols="12" v-if="item.items.length === 0">
                <v-card>
                    <v-card-text>
                        <div class="text-center text-h5">
                            No result found
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
              <v-col v-else cols="12" v-for="(subitem, index) in item.items" :key="index">
                <v-card>
                    <v-card-text>
                        <v-simple-table v-if="subitem.report_table">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-for="header in subitem.report_table[0]" :key="header">
                                            {{ header }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in subitem.report_table.slice(1)" :key="index">
                                        <td v-for="cell in row" :key="cell">
                                            {{ cell }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-else class="text-center text-h4">
                            No result found
                        </div>
                    </v-card-text>
                </v-card>
          </v-col>
          </v-row>
          </v-tab-item>
        </v-tabs-items>
          </v-container>
         <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-main>
    </v-app>
    </template>
    
    <script>
    // @ is an alias to /src
    import SingleAppBar from "@/components/common/SingleAppBar";
    import {getLab} from '@/api/cms'
    
    export default {
      name: "Shopping",
      components: {
        SingleAppBar
      },
      methods: {
          redirect(item, target = '_blank') {
              this.$gtag.event('Banner', {
            event_category: 'Shopping',
            event_label: item.src,
            value: item.url
          })
                window.open(item.url, target);
            },
          async fetchData () {
             try {
               this.loading = true
               // let banner = await getBanner()
               const lab = await getLab()
               if(lab.data) {
                if (lab.data !=='No result found.') {
                    this.items[0].items = lab.data
                } else {
                    this.items[0].items = []
                }
                }
             } catch(error) {
               console.log(error)
             } finally {
               this.loading = false
             }
           }
      },
      data() {
        return {
            tab: null,
            loading: false,
            items: [
              {title: 'Result' ,filename: 'LogoChividD.jpeg',items: [
             

              ],},
                // {title: 'มูลนิธิรามา' ,filename: 'RamaFound.png',content: 'hello',items: []},
                // {title: 'เอสซีจีเอ็กเพลส' ,filename: 'ExpressLoo.png',content: 'hello',items: []},
            ],
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        }
      },
       created () {
        this.$watch(
          () => this.$route.params,
          () => {
            this.fetchData()
          },
          // fetch the data when the view is created and the data is
          // already being observed
          { immediate: true }
        )
      }
    };
    </script>
    